import {
	Box,
	Divider,
	FormControlLabel,
	Paper,
	Stack,
	Switch,
	Typography,
} from "@mui/material";
import { CTGuardrailTraffic } from "common/atoms/ct-guardrail/CTGuardrailTraffic";
import { OutputData } from "common/atoms/ct-guardrail/types";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useAssetsDistributionStats } from "modules/bulk-asset-status-control/hooks/useAssetDistributionStats";
import { AssetStatusSlider } from "pages/asset/components/asset-detail/components/asset-status-slider";
import { AssetSliderProps } from "pages/asset/components/asset-detail/components/asset-status-slider/types";
import {
	Direction,
	SecurityStatus,
} from "pages/asset/components/asset-detail/constants";
import { AssetStatus } from "pages/assets/types";
import {
	ProgressiveEnforcementLevel,
	ProgressiveEnforcementStatus,
	ProgressiveOutboundPortEnforcementLevel,
	ProgressiveOutboundPortEnforcementStatus,
} from "pages/ports/types";
import { AssetBadges } from "pages/tags/components/tag-policy-list/components/policy-automation-drawer/components/asset-badges";
import { ProgressiveStatus } from "pages/tags/components/tag-policy-list/components/policy-automation-drawer/components/progressive-status";
import { getEnforcementStatus } from "../../PolicyAutomationDrawer";
import { PolicyChangeType, Traffic, UnreviewedTraffic } from "../../types";

interface InboundSecurityLevelCardProps extends AssetSliderProps {
	progressiveId: PolicyChangeType;
	enforcementId: PolicyChangeType;
	direction: Direction.Inbound;
	onChangeTestMode: (checked: boolean) => void;
	criteria?: string;
	initialProgressiveEnforcementLevel?:
		| ProgressiveEnforcementLevel
		| ProgressiveOutboundPortEnforcementLevel;
	selectedProgressiveEnforcementLevel?: ProgressiveEnforcementLevel;
	setSelectedProgressiveEnforcementLevel?: (
		value: ProgressiveEnforcementLevel
	) => void;
	isZeroTrustAutomationEditable: boolean;
	policyId?: string;
	policyProgressiveLastRefreshed?: string;
	progressiveTraffic?: UnreviewedTraffic;
	enforcementTraffic?: UnreviewedTraffic;
	progressiveCriteria?: Traffic;
	enforcementCriteria?: Traffic;
	updateUnreviewedTraffic?: (
		policyChangeId: PolicyChangeType,
		traffic: UnreviewedTraffic
	) => void;
	trafficReviewed?: boolean;
	currentEnforcementStatus?: AssetStatus;
	currentProgressiveStatus?: ProgressiveEnforcementStatus;
	updateAggregateTraffic?: (
		policyChangeId: PolicyChangeType,
		traffic: OutputData
	) => void;
}

interface OutboundSecurityLevelCardProps extends AssetSliderProps {
	progressiveId: PolicyChangeType;
	enforcementId: PolicyChangeType;
	direction: Direction.Outbound;
	onChangeTestMode: (checked: boolean) => void;
	criteria?: string;
	initialProgressiveEnforcementLevel?:
		| ProgressiveOutboundPortEnforcementLevel
		| ProgressiveEnforcementLevel;
	selectedProgressiveEnforcementLevel?: ProgressiveOutboundPortEnforcementLevel;
	setSelectedProgressiveEnforcementLevel?: (
		value: ProgressiveOutboundPortEnforcementLevel
	) => void;
	isZeroTrustAutomationEditable: boolean;
	policyId?: string;
	policyProgressiveLastRefreshed?: string;
	progressiveTraffic?: UnreviewedTraffic;
	enforcementTraffic?: UnreviewedTraffic;
	progressiveCriteria?: Traffic;
	enforcementCriteria?: Traffic;
	updateUnreviewedTraffic?: (
		policyChangeId: PolicyChangeType,
		traffic: UnreviewedTraffic
	) => void;
	trafficReviewed?: boolean;
	currentEnforcementStatus?: AssetStatus;
	currentProgressiveStatus?: ProgressiveOutboundPortEnforcementStatus;
	updateAggregateTraffic?: (
		policyChangeId: PolicyChangeType,
		traffic?: OutputData
	) => void;
}

type SecurityLevelCardProps =
	| InboundSecurityLevelCardProps
	| OutboundSecurityLevelCardProps;

export function SecurityLevelCard(props: SecurityLevelCardProps) {
	const { isFeatureEnabled: isProgressiveEnabled } = useFeatureFlagControl(
		FEATURES.PROGRESSIVE
	);
	const { isFeatureEnabled: isProgressiveOutboundV1Enabled } =
		useFeatureFlagControl(FEATURES.PROGRESSIVE_OUTBOUND_V1);

	const assetStats = useAssetsDistributionStats({
		criteria: props.criteria,
		field: `asset${
			props.direction === Direction.Inbound ? "inbound" : "outbound"
		}status`,
		enabled: true,
	});

	const direction =
		props.direction === Direction.Inbound ? "Inbound" : "Outbound";

	const shouldShowProgressiveStatus =
		isProgressiveEnabled &&
		(props.direction === Direction.Inbound || isProgressiveOutboundV1Enabled) &&
		props.selectedProgressiveEnforcementLevel !== undefined;

	const enforcementStatus = getEnforcementStatus(
		props.selectedStatus,
		props.simulatedStatus ?? false
	);
	const showWarning =
		props.trafficReviewed &&
		enforcementStatus !== AssetStatus.Unsecured &&
		enforcementStatus !== AssetStatus.SimulateSecureInternet &&
		!props.disabled &&
		props.isZeroTrustAutomationEditable;

	return (
		<Box component={Paper} elevation={2} p={2}>
			<Stack direction={"row"} alignItems={"center"}>
				<Typography variant="subtitle2" flex={1}>
					{window.getCTTranslatedText(
						props.direction === Direction.Inbound
							? "Attack Surface"
							: "Blast Radius"
					)}
				</Typography>
				<Typography variant="overline">
					{window.getCTTranslatedText(direction)}
				</Typography>
			</Stack>
			{shouldShowProgressiveStatus && (
				<>
					<Divider sx={{ my: 1 }} />
					<Stack id={props.progressiveId}>
						<ProgressiveStatus
							id={props.progressiveId}
							criteria={props?.criteria}
							direction={props.direction}
							asset={props.asset}
							initialStatus={props.initialProgressiveEnforcementLevel}
							selectedStatus={props.selectedProgressiveEnforcementLevel}
							setSelectedStatus={props.setSelectedProgressiveEnforcementLevel}
							disabled={props.disabled}
							policyId={props?.policyId}
							policyProgressiveLastRefreshed={
								props.policyProgressiveLastRefreshed
							}
							progressiveTraffic={props.progressiveTraffic}
							progressiveCriteria={props.progressiveCriteria}
							updateUnreviewedTraffic={props.updateUnreviewedTraffic}
							trafficReviewed={props.trafficReviewed}
							currentProgressiveStatus={props.currentProgressiveStatus}
							updateAggregateTraffic={(
								id: PolicyChangeType,
								traffic?: OutputData
							) => props.updateAggregateTraffic?.(id, traffic as OutputData)}
						/>
						<Typography
							variant="body2"
							sx={{ color: theme => theme.palette.text.secondary }}
						>
							{window.getCTTranslatedText("enforcement").toUpperCase()}
						</Typography>
					</Stack>
				</>
			)}
			<Stack
				id={props.enforcementId}
				pt={3}
				sx={{ opacity: props.isZeroTrustAutomationEditable ? 1 : 0.3 }}
			>
				<AssetBadges
					assetStats={assetStats}
					selectedStatus={props.selectedStatus}
				/>
			</Stack>
			<Box
				sx={{
					mt: 15,
					width: "100%",
					px: 4,
					opacity: props.isZeroTrustAutomationEditable ? 1 : 0.3,
				}}
			>
				<AssetStatusSlider
					{...props}
					disabled={!props.isZeroTrustAutomationEditable || props.disabled}
				/>
			</Box>
			<Stack mb={4}>
				{showWarning ? (
					<CTGuardrailTraffic
						id={props.enforcementId}
						showWarning={false}
						baseCriteria={props.enforcementCriteria}
						updateTraffic={props.updateUnreviewedTraffic}
						selectedStatus={enforcementStatus}
						currentStatus={props.currentEnforcementStatus}
						updateAggregate={(id: PolicyChangeType, traffic?: OutputData) =>
							props.updateAggregateTraffic?.(id, traffic as OutputData)
						}
					/>
				) : null}
			</Stack>
			<Stack
				alignItems={"flex-end"}
				sx={{ opacity: props.isZeroTrustAutomationEditable ? 1 : 0.3 }}
			>
				{props.selectedStatus !== SecurityStatus.Unsecure && (
					<FormControlLabel
						sx={{ m: 0 }}
						label={window.getCTTranslatedText("testMode")}
						disabled={!props.isZeroTrustAutomationEditable}
						control={
							<Switch
								value=""
								checked={props.simulatedStatus}
								onChange={(e, checked) => props.onChangeTestMode(checked)}
								color="warning"
							/>
						}
					/>
				)}
			</Stack>
		</Box>
	);
}
